import { noView } from 'aurelia-framework';
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-dependency-injection";
import { CountryModel } from "../../../../components/models/YapilyModels/CountryModel";
import { SingletonService } from "singleton";
import { I18N } from "aurelia-i18n";
import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import { EventAggregator } from 'aurelia-event-aggregator';

@noView
@autoinject
export class bitcoinTopUpLanding {
  constructor(
    private yapilyHttpClient: YapilyHttpClient,
    private router: Router,
    private singleton: SingletonService,
    private ea: EventAggregator
  ) {}

  async activate({ consent }) {
    await this.doStuff(consent);
  }

  async doStuff(consent: string) {
    try {
      const accounts = await localStorage.getItem("ibanTopUp");
      const currency = await localStorage.getItem("currencyTopUp");
      const response = await this.yapilyHttpClient.fetch("/exchange/execute", {
        method: "POST",
        body: JSON.stringify({
          accounts,
          currency,
          consent,
          paymentIdempotencyId: localStorage.getItem(
            "paymentIdempotencyId_toptup"
          ),
        }),
      });
      if (response.status >= 200 && response.status < 400) {
        this.router.navigateToRoute("bitcoin_top_up_accepted");
        this.ea.publish("app.started")
      } 
      else {
      this.router.navigateToRoute("bitcoin_top_up_refused");
      }
    } catch (e) {
      this.router.navigateToRoute("bitcoin_top_up_refused");
      console.log(e);
    }
  }
}
