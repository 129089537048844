// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/x_circle.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./bitcoin_top_up_refused.scss"></require>
  <section id="bitcoin_top_up_refused" class="flex-column">
    <div id="header" class="flex-row">
      <h1>Oops</h1>
    </div>
    <div class="flex-grow"></div>
    <img class="red_circle" src="${___HTML_LOADER_IMPORT_0___}">
    <div class="flex-grow"></div>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" click.delegate="goToHome()" i18n="home.goBackHome"></button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;