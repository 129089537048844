// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#topup {
  align-items: center;
  height: 100%;
  margin: auto;
  padding: 1rem;
  width: 100%;
}
section#topup div#header {
  padding: 1rem 0;
  margin-bottom: 1rem;
}
section#topup h1 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.2rem;
  margin: 1.1rem 0px 2.1rem 0;
  padding: 0 0rem;
  text-align: start;
  width: 100%;
}
section#topup h2 {
  font-size: 1.4rem !important;
  font-weight: 400;
}
section#topup div.payment {
  max-width: 550px;
  width: 100%;
}
section#topup div.payment div.from_change, section#topup div.payment div.to_change {
  justify-content: space-between;
}
section#topup div.payment div.from_change a, section#topup div.payment div.to_change a {
  color: #0681b1;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
}
section#topup div.container {
  gap: 0.3rem;
  justify-content: center;
  margin: 0.1rem 1rem 0.1rem 1rem;
}
section#topup div.container a {
  color: #0681b1;
  text-decoration: none;
}
section#topup div.listing div.object img {
  border-radius: 50%;
}
section#topup div.listing form.object {
  align-items: center;
  background-color: #1B2027;
  border: 1px solid transparent;
  border-radius: 16px;
  cursor: pointer;
  height: 5.5rem;
  margin: 0rem 0 1rem 0;
  max-width: 550px;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  text-align: center;
  width: 100%;
  transition: border 0.25s ease-in;
}
section#topup div.listing form.object img {
  height: 3rem;
  margin-right: 1rem;
  width: 3rem;
}
section#topup div.listing div.texte p.title {
  opacity: 0.8 !important;
}
section#topup input {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: left;
}
section#topup ::placeholder {
  color: #dfe0e2 !important;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  opacity: 0.8 !important;
  text-align: left;
}
section#topup div#flip a {
  color: #0681b1;
}
section#topup button {
  margin-bottom: 0;
}
section#topup div.amount_comment {
  text-align: center;
}
section#topup div.amount_comment div {
  gap: 0.5rem;
}
section#topup div.amount_comment div p.amount {
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 0;
}
section#topup div.amount_comment div img {
  width: 1.5rem;
}
section#topup div.amount_comment p.comment {
  color: #999ca1;
  font-size: 2rem;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/pages/bitcoin/topup/topup.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACEA;EACE,mBAAA;EAEA,YAAA;EACA,YAAA;EAEA,aAAA;EACA,WAAA;AAEF;AAAE;EACE,eAAA;EACA,mBAAA;AAEJ;AACE;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,2BAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;AACJ;AAEE;EACE,4BAAA;EACA,gBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,WAAA;AADJ;AAGI;EACE,8BAAA;AADN;AAGM;EACE,cDFc;ECGd,eAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;AADR;AAME;EACE,WAAA;EACA,uBAAA;EACA,+BAAA;AAJJ;AAMI;EACE,cDjBgB;ECkBhB,qBAAA;AAJN;AAWM;EACE,kBAAA;AATR;AAaI;EACE,mBAAA;EACF,yBDhBoB;ECiBpB,6BAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;EACA,qBAAA;EACA,gBAAA;EACA,8BAAA;EACA,oBAAA;EACA,kBAAA;EACA,WAAA;EACA,gCAAA;AAXJ;AAaM;EACE,YAAA;EACA,kBAAA;EACA,WAAA;AAXR;AAgBM;EACE,uBAAA;AAdR;AAmBE;EACE,iBAAA;EACA,gBAAA;EACA,gBAAA;AAjBJ;AAoBE;EACE,yBAAA;EACA,iBAAA;EACA,gBAAA;EACA,SAAA;EACA,uBAAA;EACA,gBAAA;AAlBJ;AAwBI;EACE,cD9EgB;ACwDtB;AA0BE;EACE,gBAAA;AAxBJ;AA2BE;EACE,kBAAA;AAzBJ;AA2BI;EACE,WAAA;AAzBN;AA2BM;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;AAzBR;AA4BM;EACE,aAAA;AA1BR;AA+BI;EACE,cD1Fa;EC2Fb,eAAA;EACA,gBAAA;AA7BN","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
