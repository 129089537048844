import { Router } from "aurelia-router";
import { WalletsManager } from "services/WalletManager.service";
import { autoinject } from "aurelia-dependency-injection";
import { WalletInfo } from "services/WalletManager.service";
import { SingletonService } from "singleton";
import { P2PWallet } from "bsv-wallet";

import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { YapilyAccountModel } from "components/models/YapilyModels/YapilyAccountModel";
import { YapilyBankModel } from "components/models/YapilyModels/YapilyBankModel";
import { User } from "components/models/UserModel";
import { DialogService } from "aurelia-dialog";
// import { checkWidth } from "components/checkwidth/checkwidth";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { PaymailWalletClient } from "http_clients/PaymailWalletClient";
const bsv = require("bsv");

@autoinject()
export class TopUp {
  private walletInfo: WalletInfo;
  private amountBtc: string;
  private commentBtc: string;
  private wallet: P2PWallet;
  private allWalletsInfo: Array<WalletInfo> = [];
  private contactInfo;
  private me: User;
  private params;
  private locked: boolean = false;
  private accounts: YapilyAccountModel = new YapilyAccountModel();
  private banks: Array<YapilyBankModel>;
  private currentAccountIndex: number;
  private currentBankIndex: number;
  private address: string = "";
  private isLoading: boolean = false;
  private paymail: string = "";
  private AmIOnInput: boolean = false;
  private displayError: boolean = false;
  private error: string = "You're friend doesn't have a wallet ";

  constructor(
    private paymailWalletHttpClient: PaymailWalletClient,
    private singleton: SingletonService,
    private walletManager: WalletsManager,
    private currentWalletName: string,
    private router: Router,
    private dialogService: DialogService,
    private userHttpClient: UsersHttpClients
  ) {
    this.singleton.setIsReturnBack(true);
    this.banks = this.singleton.getMyBanks();
    this.accounts = this.singleton.getCurrentBankAccount();
    this.currentAccountIndex = this.singleton.getCurrentAccountIndex();
    this.currentBankIndex = this.singleton.getCurrentBankIndex();
    this.params = null;
    this.singleton.setIsFromTopUp(null);
  }

  async bind() {
    await this.loadWallet();
    this.paymail = this.singleton.getSelectedPaymail();
    //this.paymail = this.singleton.getSelectedPaymail();
    let random = this.singleton.getSelectedPaymailBoolean();
    this.AmIOnInput = random;
    console.log(this.paymail);
  }

  async loadWallet() {
    const selectedWalletIndex = this.singleton.getCurrentCryptoWalletIndex();
    const allWallets: WalletInfo[] = await this.walletManager.getWalletsInfos();
    this.walletInfo = allWallets[selectedWalletIndex];
    this.getSelectedContact();
  }

  getSelectedContact() {
    this.contactInfo = this.singleton.getSelectedContactBtc();
    console.log(this.contactInfo);
  }

  async getFriendFromPaymail() {
    if (this.paymail.includes("@")) {
      this.paymail = this.paymail.toLowerCase();
      console.log(this.paymail + "Should lower case");
      this.singleton.setMyFriendPaymail(this.paymail);
      this.router.navigateToRoute("make_payments_bitcoin_amount");
    } else {
      console.log("not a paymail");
    }
  }

  // async createAddressFromFriendOwnerId() {
  //   this.me = this.singleton.getMe();
  //   let idFromFriend = await this.getFriendFromPaymail();
  //   this.contactInfo = this.singleton.getSelectedContactBtc();
  //   console.log(this.contactInfo);
  //   let myFriendId;
  //   if (!this.contactInfo) {
  //     myFriendId = idFromFriend;
  //   } else {
  //     myFriendId = this.contactInfo.accepter_id;
  //   }
  //   if (myFriendId === this.me._id) {
  //     console.log("can't send balance to urself");
  //     myFriendId = this.contactInfo.requester_id_fk;
  //   }
  //   try {
  //     let request = await this.paymailWalletHttpClient.fetch(
  //       `/wallets/wallet_by_owner/${myFriendId}/address/fresh`
  //     );
  //     let is200Ok = await checkResponseStatus(request);
  //     let addressCreated = await is200Ok.json();
  //     this.singleton.setMyFriendIdForBsvPayments(myFriendId);
  //     return addressCreated.address;
  //   } catch (e) {
  //     return e;
  //   }
  // }

  /** This function initiates a payment */

  // async setAddressGoNextPage() {
  //   try {
  //     this.isLoading = true;
  //     console.log(this.isLoading);
  //     let addressCreated = await this.createAddressFromFriendOwnerId();
  //     this.singleton.setMyFriendAddressCreated(this.paymail);
  //     this.paymail = this.paymail.toLowerCase();
  //     this.singleton.setMyFriendPaymail(this.paymail);
  //     console.log(this.paymail);
  //     // checkWidth(MakePaymentsBitcoinAmount,"make_payments_bitcoin_amount", this.dialogService, this.router);
  //     this.router.navigateToRoute("make_payments_bitcoin_amount");
  //   } catch (e) {
  //     return;
  //   }
  // }

  // async getWalletAddress() {
  //   let walletId = await this.getWalletFromOwnerIdAndName();

  //   this.me = this.singleton.getMe();

  //   let request = await this.paymailWalletHttpClient.fetch(
  //     `/wallets/${walletId}/address/fresh`
  //   );
  //   let is200Ok = await checkResponseStatus(request);
  //   let response = await is200Ok.json();
  //   console.log(response.address);
  //   this.address = response.address;
  // }

  // async getWalletFromOwnerIdAndName() {
  //   this.allWalletsInfo = await this.walletManager.getWalletsInfos(); //We have an array with walletsInfo
  //   const currentWalletInfo =
  //     this.allWalletsInfo[this.singleton.getCurrentCryptoWalletIndex()];

  //   let request = await this.paymailWalletHttpClient.fetch(
  //     "/wallets/ownerId/" + currentWalletInfo.name
  //   );
  //   let is200Ok = await checkResponseStatus(request);
  //   let wallet = await is200Ok.json();

  //   await this.updateLocalWalletToNewN(currentWalletInfo, wallet.n);

  //   console.log(wallet);
  //   console.log(wallet.n);
  //   return wallet._id;
  // }

  /**
   * Updates local wallet to the N we got from the backend and saves it
   */
  async updateLocalWalletToNewN(currentWalletInfo: WalletInfo, newN: number) {
    let currentWalletObject: P2PWallet = this.walletManager.get(
      currentWalletInfo.name
    );
    console.log(currentWalletObject.lastUsedIndex);
    currentWalletObject.lastUsedIndex = newN + 1;
    this.walletManager.set(currentWalletInfo.name, currentWalletObject);
    console.log(currentWalletObject.lastUsedIndex);
  }

  // async getPaymailFromName() {
  //   this.walletInfo;
  //   let request = await this.paymailWalletHttpClient.fetch(
  //     "/wallets/getFromName/" + this.walletInfo.name
  //   );
  //   let is200OK = await checkResponseStatus(request);
  //   let reponse = await is200OK.json();
  //   return reponse;
  // }

  async goToTopUpAmount() {
    try {
      //console.log(addressCreated);
      //checkWidth(MakePaymentsBitcoinAmount,"make_payments_bitcoin_amount", this.dialogService, this.router);
      // console.log(addressCreated);
      // if (addressCreated.msg === "Wallet doesn't exist") {
      //   this.displayError = true;
      //   return;
      // }
      // let addressCreated = await this.createAddressFromFriendOwnerId();
      this.isLoading = true;
      this.singleton.setMyFriendAddressCreated(this.paymail);
      this.router.navigateToRoute("bitcoin_top_up_amount");
    } catch (e) {
      return;
    }
  }

  setAddressInput(AmIOnInput: boolean) {
    this.AmIOnInput = AmIOnInput;
    console.log(this.AmIOnInput);
  }

  goToChooseAccount() {
    this.router.navigateToRoute("choose_wallet", { wallet_type: "bank" });
  }

  goToContactsSelectBitcoin() {
    //checkWidth(ContactsSelectBitcoin,"contacts_select_bitcoin", this.dialogService, this.router);
    this.singleton.setIsFromTopUp(true);
    this.router.navigateToRoute("contacts_select_bitcoin");
  }
}
