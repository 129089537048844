// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/user-circle.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/currency/bitcoin/bsvfull.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./topup.scss"></require>
  <require from="../../../components/number-format"></require>
  <require from="../../../components/loader-ring/loader-ring"></require>
  <require from="../../../components/banka-contact/banka-contact"></require>
  <require from="../../../components/ValueConverters/iban-converter"></require>
  <require from="../../../components/ValueConverters/currency-denomination"></require>  <section id="topup" class="flex-column">
    <div class="payment">
      <h1 t="bitcoin.topup.title">Top Ups</h1> <!---->
      <div class="from_change flex-row">
        <h2 t="home.from"></h2>
        <a t="home.change" click.delegate="router.navigateToRoute('choose_wallet', {wallet_type: 'bitcoin'})"></a>
      </div>
      <div class="listing">
        <div class="object flex-row" click.delegate="goToChooseAccount()">
          <img src.bind="banks[currentBankIndex].institutionDetails.media[0].source">
          <div class="texte flex-column">
            <p class="title">\${banks[currentBankIndex].accounts[currentAccountIndex].accountNames[0].name || banks[currentBankIndex].accounts[currentAccountIndex].type}</p>
            <p class="desc">\${banks[currentBankIndex].accounts[currentAccountIndex].accountIdentifications[0].identification | ibanConverter}</p>
          </div>
          <div class="flex-grow"></div>
          <p class="solde">\${banks[currentBankIndex].accounts[currentAccountIndex].currency | currencyDenomination}\${banks[currentBankIndex].accounts[currentAccountIndex].balance | numberFormat:'0,0.00'}</p>
        </div>
      </div>
      <div class="from_change flex-row">
        <h2 t="home.to"></h2>
        <a t="home.change" click.delegate="goToContactsSelectBitcoin()"
          if.bind="!isAddressInput && locked === false"></a>
      </div>
      <div class="recipient">
        <banka-contact contact.bind="contactInfo" if.bind="contactInfo && AmIOnInput === false"
          click.delegate="goToContactsSelectBitcoin()">
        </banka-contact>
      </div> 
       <div if.bind="!contactInfo && AmIOnInput === false" class="listing">
        <div class="object flex-row" click.delegate="goToContactsSelectBitcoin()">
          <img src="${___HTML_LOADER_IMPORT_0___}" />
          <div class="texte flex-column">
            <p class="title"><span t="bitcoin.payment.make_payment_select_recipient_bitcoin"></span></p>
            <p class="desc"></p>
          </div>
          <div class="flex-grow"></div>
        </div>
      </div>
      <div class="listing" if.bind="AmIOnInput === true">
        <form id="receiver" class="object flex-row">
          <img src="${___HTML_LOADER_IMPORT_1___}" />
          <input autofocus value.bind="paymail" class="object"
            t="[placeholder]bitcoin.payment.friend_address" disabled.bind="locked === true" />
        </form>
      </div>
      <!-- <div id="flip">
        <a click.delegate="setAddressInput(true)" if.bind="AmIOnInput === false && locked === false"
          t="bitcoin.payment.address_input_p2"></a>
        <a click.delegate="setAddressInput(false)" if.bind="AmIOnInput === true && locked === false"
          t="bitcoin.payment.contact_input_p2"></a>
      </div> -->
    </div>
    <div class="flex-grow"></div>
    <div class="flex-grow"></div>
    <p class="error flex-column" if.bind="displayError">\${error}</p>
    <button class="btn btn-primary" click.delegate="goToTopUpAmount()"
      disabled.bind="!paymail || isLoading" form="receiver">
      <span t="home.next" if.bind="!isLoading"></span>
      <loader-ring class="loader-btn" if.bind="isLoading"></loader-ring>
    </button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;