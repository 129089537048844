import { Router } from 'aurelia-router';
import { checkResponseStatus } from 'http_clients/checkResponseStatus';
import { autoinject } from 'aurelia-dependency-injection';
import { SingletonService } from 'singleton';
import { YapilyHttpClient } from 'http_clients/YapilyHttpClient';
import { YapilyPaymentAuthRequest } from 'components/models/YapilyModels/YapilyPaymentAuthRequests';
import { YapilyPaymentRequestModel } from 'components/models/YapilyModels/YapilyPaymentRequestModel';
import { YapilyAmountModel } from 'components/models/YapilyModels/YapilyAmountModel';
import { YapilyPayeeModel } from 'components/models/YapilyModels/YapilyPayeeModel';
import { YapilyPaymentsDetailsModel } from 'components/models/YapilyModels/YapilyPaymentsDetailsModel';
import { YapilyBanksDetailsModel } from 'components/models/YapilyModels/YapilyBanksDetailsModel';
import { SubscriptionHttpClient } from 'http_clients/SubscriptionHttpClient';
import { UsersHttpClients } from 'http_clients/UsersHttpClients';
import { AuthService } from 'aurelia-auth';
import { json } from "aurelia-fetch-client";
import { YapilyAccountIdentificationModel } from 'components/models/YapilyModels/YapilyAccountIdentificationModel';
import { User } from 'components/models/UserModel';

@autoinject
export class BitcoinTopUpAccepted {
  private institution: YapilyBanksDetailsModel;
  private accounts: any;
  private paymentsAuthInformation: YapilyPaymentRequestModel;
  private received_consent: string;
  private userUuid: string;
  private paymentsDetails: YapilyPaymentsDetailsModel;
  private isLoading: boolean = false;
  private me: User;
  private payerName: string;
  private iban: string;

  constructor(private singleton: SingletonService,
    private yapilyHttpClient: YapilyHttpClient,
    private subscriptionHttpClient: SubscriptionHttpClient,
    private userHttpClient: UsersHttpClients,
    private authService: AuthService,
    private router: Router) {
  }

  activate(params) {
    try {
      if (params['error-description'] != undefined || params.consent != undefined) {
        this.router.navigateToRoute('subscription_failure')
      } else {
        let application_user_id = params['error-description']
        this.institution = params.institution
        this.received_consent = params.consent
        this.userUuid = params.users
        this.iban = this.singleton.getIban()
        this.payerName = this.singleton.getPayerName()
      }
    } catch (e) {
      if (this.me.role == 'admin') {
        alert(e)
      }
      console.log(e)
    }
  }

  attached() {
  }

  async getMe() {
    const rep = await this.authService.getMe()
    const repa = await this.authService.getTokenPayload()

  }

  goBackHome() {
    this.router.navigateToRoute('home');
  }
}
